<template>
  <div>
    <template v-if="loader">
      <h1 class="text-center font-size-large mb-3">Finalizando a integração</h1>
      <Loader
        :size="'big'"
      />
    </template>
    <template v-if="error">
      <div class="text-center mt-5">
        <div class="mb-3">
          <i class="fa fa-warning font-size-xLarge"></i>
        </div>
        <p>Erro ao criar o token de acesso</p>
        <router-link
          class="btn btn-primary mt-2"
          to="/"
        >
          Voltar
        </router-link>
      </div>
    </template>
  </div>
</template>

<script>

import GoogleIntegrationService from '@/modules/integrations/services/google-integration-service'
import UsersService from '@/modules/users/services/users-service'
import { mapState } from 'vuex'

import Loader from '@/components/common/Loader/Loader'

export default {
  components: {
    Loader
  },
  data () {
    return {
      error: false,
      hasGeneratedToken: false,
      loader: true
    }
  },
  mounted () {
    if (this.user) {
      this.checkIfUserIsManager()
      this.generateAccessToken()
    }
  },
  watch: {
    user (newUser) {
      this.checkIfUserIsManager()
      this.generateAccessToken()
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    })
  },
  methods: {
    /**
     * Check if user is manager or not
     */
    checkIfUserIsManager () {
      if (this.user.role.slug !== 'user-manager' && this.user.role.slug !== 'administrator') this.$router.push('/')
    },
    /**
     * Generate access token
     */
    async generateAccessToken () {
      if (this.hasGeneratedToken) return
      try {
        await GoogleIntegrationService.generateAccessToken(this.$route.query.code)
        this.hasGeneratedToken = true
        await UsersService.me()
        this.$router.push('/')
      } catch (error) {
        this.loader = false
        this.error = true
        error.handleGlobally && error.handleGlobally()
      }
    }
  }
}
</script>
