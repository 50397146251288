import axios from '@/plugins/axios'

/**
 * Generate access token
 */
const generateAccessToken = async code => {
  try {
    const response = await axios.post('integrations/google/access-token', {
      code: code
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get oAuth URL
 */
const getOAuthURL = async () => {
  try {
    const response = await axios.get('integrations/google/oauth-url')
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  generateAccessToken,
  getOAuthURL
}
